import React, { useEffect, useState, useRef } from "react";
import { nanoid } from "nanoid";
import { MdRemoveRedEye } from "react-icons/md";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Filter from "./AssignmentManagementFilter/AssignmentManagementFilter";
import { useDispatch, useSelector } from "react-redux";
import { postMethodData, getMethodData } from "../../../ApiMethods/Api";
import {
  setAssignment,
  setAssignmentStatusList,
  setSelectedRow,
  setServiceList,
} from "../../../Redux-Toolkit/AssignmentSlice";
import { FaHospitalUser } from "react-icons/fa6";
import encryptParamID from "../../../utils/encryptParamID";
import decryptParamID from "../../../utils/decryptParamID";
import ReactPagination from "../../../utils/ReactPagination";
import { formatDate, inactiveFlagClass } from "../../../utils/helpers";
import { updatemetricsFilters } from "../../../Redux-Toolkit/MatricsSlice";
import invoice from "../../../assets/JNJ-icons/invoice.svg";
import LoadingPage from "../../../Common/LoaderPage/LoadingPage";

const AssignmentManagement = () => {

  const params = decryptParamID(useParams());
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get("page");
  const urlHasParams = Object.keys(params).length > 0;

  const assignmentSearchListData = useSelector((state) => state?.assignments?.assignments) || []; //for customers search list of JNJ customers
  const assignmentFilters = useSelector((state) => state?.assignments?.assignmentFilters);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const [assignmentDataLoading, setAssignmentDataLoading] = useState(false);
  const [entityName, setEntityName] = useState("");
  const rowRefs = useRef([]);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(() => {
    const page = Number(searchParams.get("page"));
    return isNaN(page) || page <= 0 ? 1 : page; // Fallback to 1 if invalid
  });
  const ITEMS_PER_PAGE = 20;

  const goBack = () => {
    window.history.back(); // Go back to the previous page
  };

  const encryptId = (id) => encryptParamID(id);
  const activeRowRef = useRef(null);

  const selectedRow = useSelector((state) => state?.assignments?.selectedTableRow);
  const id = useParams();

  /**
   * UseEffect to get the current page number from the URL
   */

  useEffect(() => {
    setCurrentPage(searchParamPage ?? 1);
  }, [searchParamPage]);

  useEffect(() => {
    fetchFilterListData();
    fetchReservationSearchList();
  }, [assignmentFilters, currentPage]);

  useEffect(() => {
    fetchEntityNames();
  }, []);

  const fetchFilterListData = () => {
    const filterEndpoints = [
      { url: "/resactioncode", action: setAssignmentStatusList },
      { url: "/ResAssgnType", action: setServiceList },
    ];

    filterEndpoints.forEach(({ url, action }) => {
      getFilterListDataMethod(url, (response) => {
        if (response?.data?.data?.length) {
          dispatch(action(response?.data?.data));
        }
      });
    });
  };

  const fetchReservationSearchList = () => {
    reservationSearchList(
      "/ReservationAssignments",
      {
        ...assignmentFilters,
        page: Number(currentPage),
        limit: ITEMS_PER_PAGE,
      },
      (response) => {
        if (!response?.data?.status) {
          dispatch(setAssignment([]));
          setPageCount(0);
        } else if (response?.data?.status) {
          dispatch(setAssignment(response?.data?.data));
          setPageCount(Math.ceil(response?.data?.totalData / ITEMS_PER_PAGE));
        }
      }
    );
  };

  const getFilterListDataMethod = async (url, callback) => {
    try {
      const response = await getMethodData(url);
      callback(response);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer category list:",
        error
      );
    }
  };

  /**
   * UseEffect to get the entity name based on the params from the URL
   * It can be Reservation, Contractor, etc. to know for which entity the assignment is being viewed
   */

  const fetchEntityNames = async () => {
    for (let key in params) {
      if (keyMapping[key]) {
        let newKey = key.replace("id", "ID");
        const endpoint = endpointMapping[key];
        const body = { [newKey]: Number(params[key]) };
        getEntityName(endpoint, body, (response) => {
          if (response?.data?.data?.length === 0) {
            setEntityName("N/A");
          } else {
            const property = propertyMapping[key];
            setEntityName(`| ${response?.data?.data[0]?.[[property]]}`);
          }
        });
      }
    }
  };

  /**
   * Mapping for the key names to be used in setting entity names
   */

  const keyMapping = {
    customerid: "Customer ID",
    claimantid: "Claimant ID",
    contractorid: "Contractor ID",
    claimnumber: "Claim Number",
    reservationid: "Reservation ID",
  };

  /**
   * Mapping for the endpoints to be used in getting the entity names
   */

  const endpointMapping = {
    customerid: "/CustomerSelDetail",
    claimantid: "/ClaimantSelDetail",
    contractorid: "/ContractorSelDetail",
  };

  const propertyMapping = {
    customerid: "companyName",
    claimantid: "fullName",
    contractorid: "company",
  };

  /**
   * Function to get the entity name based on the params from the URL
   * @param {string} url - The endpoint to be used to get the entity name
   * @param {object} data - The data to be sent in the body of the request
   */

  const getEntityName = async (url, data, callback) => {
    const body = data;
    if (url) {
      try {
        const response = await postMethodData(url, body);
        callback(response);
      } catch (error) {
        console.error(
          "An error occurred while trying to get the customer search list:",
          error
        );
        setEntityName("");
      }
    }
  };

  const reservationSearchList = async (url, filterdata, callback) => {
    const body = filterdata || {
      reservationID: id,
      reservationsAssignmentsID: 0,
      contractorID: 0,
      dateFrom: "",
      dateTo: "",
      claimID: 0,
      claimantID: 0,
      customerID: 0,
      conAssignStatus: 0,
      page: Number(currentPage),
      limit: ITEMS_PER_PAGE,
    };
    try {
      setAssignmentDataLoading(true);
      const response = await postMethodData(url, body);
      callback(response);
      setAssignmentDataLoading(false);
    } catch (error) {
      console.error(
        "An error occurred while trying to get the customer search list:",
        error
      );
      setAssignmentDataLoading(false);
      dispatch(setAssignment([]));
    }
  };

  /**
   * Options to be passed to the ReactPagination component
   */

  const pageChangeOptions = {
    endPointFunction: reservationSearchList,
    endpoint: "/ReservationAssignments",
    componentFilter: assignmentFilters,
    dispatchAction: setAssignment,
    itemsPerPage: ITEMS_PER_PAGE,
    scrollElementId: "tablescroll", // Optional, only if you want to scrol,
  };

  //-------------------------------methods to be used to maintain the scrolling of page after pagination---------------------//

  useEffect(() => {
    // Scroll to the saved index if it exists
    if (selectedRow !== null && tableRef.current) {
      const td = document.getElementById(selectedRow);
      if (td) {
        td.scrollIntoView({ block: "center" });
      }
    }
  }, [assignmentSearchListData]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  //-------------------------------------------------------------------------- ended -------------//

  /**
   * Component to route to assign contractor page based on whether the contractor is found or not.
    *If contractor found, then directly route to assign contractor page.
    *Else, first run ContractorWebJobSearch API to get the contractor list from backend and then once we get the response, route to assign contractor page.
    @param {} data
   * @returns nothing
   */
  const assignContractor = async (data) => {
    const body = {
      reservationID: data?.reservationid,
      reservationAssignmentsID: data?.reservationsAssignmentsID,
    };

    if (data?.isContractorFound === 1) {
      if (data?.assgncode === "TRANSLATE" || data?.assgncode === "INTERPRET") {
        navigate(
          `/assignments-management/reservation/${data?.contractorid}/${data?.reservationid}/dozip/${data?.doZipCode}/${data?.reservationsAssignmentsID}/${data?.quantity}`
        );
      } else {
        navigate(
          `/assignments-management/reservation/${data?.contractorid}/${data?.reservationid}/puzip/${data?.puZipCode}/${data?.reservationsAssignmentsID}/${data?.quantity}`
        );
      }
    } else {
      try {
        const response = await postMethodData("/ContractorWebJobSearch", body);
        if (response?.data?.status) {
          if (
            data?.assgncode === "TRANSLATE" ||
            data?.assgncode === "INTERPRET"
          ) {
            navigate(
              `/assignments-management/reservation/${data?.contractorid}/${data?.reservationid}/dozip/${data?.doZipCode}/${data?.reservationsAssignmentsID}/${data?.quantity}`
            );
          } else {
            navigate(
              `/assignments-management/reservation/${data?.contractorid}/${data?.reservationid}/puzip/${data?.puZipCode}/${data?.reservationsAssignmentsID}/${data?.quantity}`
            );
          }
        }
      } catch (error) {
        console.error("occurred", error);
      }
    }
  };

  /* tooltip values */
  const getContractorTooltip = (data) => {
    if (data?.rsvacCode === "Reserved") {
      return data?.isContractorFound === 0
        ? "No Contractor Assigned"
        : "Assign Contractor";
    } else if (data?.rsvacCode === "ResCancel") {
      return "View Assigned Contractor";
    } else {
      return "";
    }
  };

  const cursorRsvacCodeClass = (rsvacCode) => {
    return rsvacCode === "Reserved" || rsvacCode === "ResCancel"
      ? "cursor-pointer"
      : "cursor-default";
  };

  const textColorConditionClass = (data) => {
    if (
      data?.inactiveflag === 0 &&
      data?.rsvacCode === "Reserved" &&
      !data?.contractor
    ) {
      return data?.isContractorFound === 0 ? "text-[#fca554]" : "text-[red]";
    } else {
      return "text-[red]";
    }
  };

  const jobStatusClass = (jobStatus) => {
    if (jobStatus === 0) {
      return "text-[#63b0e0]";
    } else if (jobStatus === -1 || jobStatus === -2) {
      return "text-[red]";
    } else if (jobStatus === 1) {
      return "text-[#7ac46b]";
    } else {
      return "";
    }
  };

  const getJobStatusText = (jobStatus) => {
    switch (jobStatus) {
      case 0:
        return "[Request Sent]";
      case -1:
        return "[Job Canceled]";
      case 1:
        return "[Job Accepted]";
      case -2:
        return "[Job Withdrawn]";
      default:
        return "";
    }
  };

  const assignmentJobStatusShow = (assignmentJobStatus) => {
    switch (assignmentJobStatus) {
      case "Ongoing":
        return "Ongoing";
      case "Complete":
        return "Completed";
      default:
        return "Pending";
    }
  };

  /**
   * helper functions for return the data which wants to show on the particular table column
   */
  //--------------------------helper functions below----------------------------------------------//
  const getRowClassName = (data, selectedRow) => {
    return `
      ${selectedRow === data.reservationsAssignmentsID ? "activeTableRow" : ""} 
      ${data?.inactiveflag === -1 ? "assignmentCancelledRow" : ""}
    `;
  };

  const renderClaimantName = (data, navigate) => {
    if (data?.claimantName !== undefined && !data?.isDelete) {
      navigate(
        `/claimant-management/claimant-details/${encryptId(data?.claimantid)}`
      );
    }
  };

  const renderContractorCompany = (data, navigate) => {
    if (data?.contractorCompany || data?.contractorintfid) {
      return (
        <div key={nanoid()}>
          <span className="tooltip" data-tip="View details">
            <div
              className="font-bold capitalize text-[#7ac46b] cursor-pointer"
            >
              <button
                className="text-left"
                onClick={() => {
                  navigate(
                    `/contractor-management/contractor-details/${encryptId(
                      data?.contractorid
                    )}`
                  );
                }}
              >
                {data?.contractorCompany ?? "N/A"}
              </button>
            </div>
          </span>
        </div>
      );
    } else {
      return (
        <span
          className={`${data?.rsvacCode === "Reserved" || data?.rsvacCode === "ResCancel"
            ? "tooltip"
            : ""
            }`}
          data-tip={getContractorTooltip(data)}
        >
          <div
            className={`${cursorRsvacCodeClass(data?.rsvacCode)} 
              ${textColorConditionClass(data)}`}
          >
            <button
              onClick={() => {
                if (data?.rsvacCode === "Reserved" && data?.inactiveflag === -1 &&
                  !data?.contractor) {
                  navigate(`/assignments-management/reservation-assignment-details/${encryptId(
                    data?.reservationsAssignmentsID
                  )}`)
                }
                else if (
                  data?.rsvacCode === "Reserved" ||
                  data?.rsvacCode === "ResCancel" ||
                  data?.inactiveflag === 0
                ) {
                  assignContractor(data);
                }
              }}
            >
              {"Not Assigned\n"}
            </button>
          </div>
        </span>
      );
    }
  };

  const renderJobStatus = (data) => {
    return (
      <span className={`${jobStatusClass(data?.jobStatus)}`}>
        {getJobStatusText(data?.jobStatus)}
      </span>
    );
  };

  const renderAssignmentMetrics = (data, dispatch) => {
    if (data?.inactiveflag === 0 && data?.assignmentJobStatus === "Complete") {
      const tooltipText =
        data?.contractorMetricsFlag === 1
          ? "View Metrics"
          : "Metrics not submitted yet";

      return (
        <span
          className="tooltip tooltip-left text-[black]"
          data-tip={tooltipText}
        >
          {data?.contractorMetricsFlag === 1 ? (
            <Link
              className="cursor-pointer"
              to={`/metrics-management/assignment/${encryptId(
                data?.reservationsAssignmentsID
              )}`}
              onClick={() => {
                dispatch(
                  updatemetricsFilters({
                    reservationsAssignmentsID: data?.reservationsAssignmentsID,
                    reservationID: data?.reservationid,
                  })
                );
              }}
            >
              <img
                className="cursor-pointer max-w-none"
                alt="matrics"
                src={invoice}
              />
            </Link>
          ) : (
            <span className="cursor-pointer">
              <img
                className="cursor-pointer max-w-none"
                alt="matrics"
                src={invoice}
              />
            </span>
          )}
        </span>
      );
    }
    return null; // Return null if conditions are not met
  };

  const renderAssignmentButton = (data) => {
    if (
      data?.inactiveflag === 0 &&
      (data?.rsvacCode === "Reserved" || data?.rsvacCode === "ResCancel") &&
      !data?.contractor
    ) {
      return (
        <span
          className={`tooltip tooltip-left
          ${data?.isContractorFound === 0 ? "text-[#fca554]" : "text-[#c53d3d]"}
        `}
          data-tip={getContractorTooltip(data)}
        >
          <button
            onClick={() => {
              assignContractor(data);
            }}
            className="cursor-pointer"
          >
            <FaHospitalUser className="cursor-pointer" />
          </button>
        </span>
      );
    }
  };
  //---------------------------------------helper functions ended----------------------------------//

  return (
    <>
      <div className="flex justify-between">
        <div className=" mb-4">
          <p className=" text-2xl font-semibold capitalize">
            {!urlHasParams
              ? "Assignment Management"
              : `Assignment List for ${Object.keys(params)
                .slice(0, 1)
                .map(
                  (key) =>
                    `${keyMapping[key] || key}: ${params[key]} ${entityName}`
                )
                .join(", ")}`}
          </p>
        </div>

        {urlHasParams && (
          <div>
            <Link
              onClick={goBack}
              className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"
            >
              Back
            </Link>
          </div>
        )}
      </div>

      <div className="relative">
        {!urlHasParams && <Filter />}
        <div className="card bg-base-100 shadow-md p-6 mt-8">
          <div className="overflow-x-auto w-full">
            <table
              ref={tableRef}
              id="tablescroll"
              className="table table-zebra w-full"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Assignment ID</th>
                  <th>Reservation ID</th>
                  <th>Claimant</th>
                  <th>Contractor Company</th>
                  <th>Service</th>
                  <th>Pickup</th>
                  <th>Action Code</th>
                  <th>Job Status</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody className="text-sm relative">
                {assignmentDataLoading &&
                  Array.from({ length: 5 }).map((_) => (
                    <tr key={nanoid()}>
                      <td colSpan={13} className="animate-pulse py-6"></td>
                    </tr>
                  ))}
                {!assignmentDataLoading &&
                  assignmentSearchListData?.length !== 0 &&
                  assignmentSearchListData?.map((data, index) => (
                    <tr
                      key={`${data?.reservationsAssignmentsID} - ${data?.linenum}`}
                      id={`${data.reservationsAssignmentsID}`}
                      ref={(ele) => {
                        rowRefs.current[index] = ele;
                        if (index === selectedRow) {
                          activeRowRef.current = ele;
                        }
                      }}
                      className={`${getRowClassName(data, selectedRow)} ${(currentPage - 1) * ITEMS_PER_PAGE + index + 1}`}
                      onClick={() => {
                        dispatch(
                          setSelectedRow(data.reservationsAssignmentsID)
                        );
                      }}
                    >
                      <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
                      {/* <td>{data?.reservationsAssignmentsID}</td> */}
                      <td>{`${data?.reservationid} - ${data?.linenum}`}</td>
                      <td>{data?.reservationid}</td>
                      <td>
                        <span
                          className="tooltip text-left"
                          data-tip="View Details"
                        >
                          <div className="flex space-x-3">
                            <div>
                              <div
                                className="font-bold capitalize text-[#7ac46b] cursor-pointer"
                              >
                                <button
                                  className=" text-left"
                                  onClick={() =>
                                    renderClaimantName(data, navigate)
                                  }
                                >
                                  {data?.claimantName ?? "N/A"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </span>
                      </td>
                      <td>
                        <div>
                          {renderContractorCompany(data, navigate)}
                          <div>{renderJobStatus(data)}</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="">{data?.assgncode || "N/A"}</div>
                          <span>{`[${data?.resTripType || "N/A"}]`}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ whiteSpace: "nowrap" }}>
                          {formatDate(data?.reservationDate)}
                          <div>{data?.pickupTime || "N/A"}</div>
                        </div>
                      </td>
                      <td className=" capitalize">
                        <div>{data?.actionCode || "N/A"}</div>
                      </td>
                      <td className="whitespace-normal capitalize max-w-xs">
                        <div>
                          {assignmentJobStatusShow(data?.assignmentJobStatus)}
                        </div>
                      </td>
                      <td>
                        <label
                          htmlFor="my-modal-5"
                          className={`btn capitalize ${inactiveFlagClass(data)} text-[#000] border-hidden  btn-xs cursor-default`}
                        >
                          {data?.inactiveflag ? "Inactive" : "Active"}
                        </label>
                      </td>
                      <td className=" text-base">
                        <div className="flex space-x-4">
                          <button title="">
                            <span className="flex space-x-4">
                              <span
                                className="tooltip text-left"
                                data-tip="View Details"
                              >
                                <Link
                                  className="cursor-pointer"
                                  to={`/assignments-management/reservation-assignment-details/${encryptId(
                                    data?.reservationsAssignmentsID
                                  )}`}
                                >
                                  <MdRemoveRedEye className="cursor-pointer" />
                                </Link>
                              </span>

                              {renderAssignmentButton(data)}

                              {renderAssignmentMetrics(data, dispatch)}
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                {!assignmentDataLoading &&
                  assignmentSearchListData?.length === 0 && (
                    <tr>
                      <td colSpan={11} className="my-10 text-center">
                        <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                        <p className=" font-semibold text-lg">
                          No Assignment Found
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            {assignmentSearchListData?.length !== 0 && (
              <ReactPagination
                forcePage={currentPage - 1}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                pageChangeOptions={pageChangeOptions}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
        {assignmentDataLoading && (
          <div
            className="fixed inset-0  top-[1px] rounded-sm"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.3)" }}
          >
            <LoadingPage />
          </div>
        )}
      </div>
    </>
  );
};

export default AssignmentManagement;
